import { EventEmitter } from "events";
import { Room } from "matrix-js-sdk/src/matrix";
import { FILTER_CHANGED, IFilterCondition } from "matrix-react-sdk/src/stores/room-list/filters/IFilterCondition";
import { IDestroyable } from "matrix-react-sdk/src/utils/IDestroyable";
import { setHasDiff } from "matrix-react-sdk/src/utils/sets";
import { ChatFolderStore } from "./ChatFolderStore";

export class ChatFolderFilterCondition extends EventEmitter implements IFilterCondition, IDestroyable {
    private roomIds = new Set<string>();
    private folderId: string = "";

    public isVisible(room: Room): boolean {
        return ChatFolderStore.instance.isRoomInFolder(room.roomId, this.folderId);
    }

    private onStoreUpdate = async (forceUpdate = false): Promise<void> => {
        const beforeRoomIds = this.roomIds;
        // clone the set as it may be mutated by the folder store internally
        this.roomIds = new Set(ChatFolderStore.instance.getFolderFilteredRoomIds(this.folderId));

        if (forceUpdate || setHasDiff(beforeRoomIds, this.roomIds)) {
            this.emit(FILTER_CHANGED);
            // XXX: Room List Store has a bug where updates to the pre-filter during a local echo of a
            // tags transition seem to be ignored, so refire in the next tick to work around it
            setImmediate(() => {
                this.emit(FILTER_CHANGED);
            });
        }
    };

    public updateChatFolder(folderId: string): void {
        ChatFolderStore.instance.off(this.folderId, this.onStoreUpdate);
        ChatFolderStore.instance.on((this.folderId = folderId), this.onStoreUpdate);
        this.onStoreUpdate(true); // initial update from the change to the folders
    }

    public destroy(): void {
        ChatFolderStore.instance.off(this.folderId, this.onStoreUpdate);
    }
}
