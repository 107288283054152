import { RoomListStore as Interface } from "matrix-react-sdk/src/stores/room-list/Interface";
import SpaceStore from "matrix-react-sdk/src/stores/spaces/SpaceStore";
import { SpaceKey, UPDATE_SELECTED_SPACE } from "matrix-react-sdk/src/stores/spaces/index";
import { ChatFolderFilterCondition } from "./ChatFolderFilterCondition";
import { ChatFolderStore, UPDATE_SELECTED_FOLDER } from "./ChatFolderStore";
import { MetaSpace } from "../../../../stores/spaces";

/**
 * Watches for changes in chat folders to manage the filter on the provided RoomListStore
 */
export class ChatFolderWatcher {
    private readonly filter = new ChatFolderFilterCondition();

    private activeSpace: SpaceKey = SpaceStore.instance.activeSpace;
    private activeFolder: string = ChatFolderStore.instance.activeFolder;

    public constructor(private store: Interface) {
        if (this.needsFilter(this.activeSpace)) {
            this.updateFilter();
            store.addFilter(this.filter);
        }
        SpaceStore.instance.on(UPDATE_SELECTED_SPACE, this.onSelectedSpaceUpdated);
        ChatFolderStore.instance.on(UPDATE_SELECTED_FOLDER, this.onSelectedFolderUpdated);
    }

    private needsFilter(spaceKey: SpaceKey): boolean {
        return spaceKey === MetaSpace.ChatFolders;
    }

    private onSelectedSpaceUpdated = (activeSpace: string): void => {
        this.onSelectedFolderUpdated(this.activeFolder, activeSpace);
    }

    private onSelectedFolderUpdated = (activeFolder: string, activeSpace = this.activeSpace): void => {
        if (activeFolder === this.activeFolder && activeSpace === this.activeSpace) return; // nop

        const neededFilter = this.needsFilter(this.activeSpace);
        const needsFilter = this.needsFilter(activeSpace);

        this.activeSpace = activeSpace;
        this.activeFolder = activeFolder;

        if (needsFilter) {
            this.updateFilter();
        }

        if (!neededFilter && needsFilter) {
            this.store.addFilter(this.filter);
        } else if (neededFilter && !needsFilter) {
            this.store.removeFilter(this.filter);
        }
    };

    private updateFilter = (): void => {
        this.filter.updateChatFolder(this.activeFolder);
    };
}
