import { ClientEvent, MatrixEvent, Room, RoomEvent, RoomStateEvent } from "matrix-js-sdk/src/matrix";
import { AsyncStoreWithClient } from "matrix-react-sdk/src/stores/AsyncStoreWithClient";
import defaultDispatcher from "matrix-react-sdk/src/dispatcher/dispatcher";
import DMRoomMap from "matrix-react-sdk/src/utils/DMRoomMap";
import SettingsStore from "matrix-react-sdk/src/settings/SettingsStore";
import { mapDiff } from "matrix-react-sdk/src/utils/maps";
import { setHasDiff } from "matrix-react-sdk/src/utils/sets";
import SpaceStore from "matrix-react-sdk/src/stores/spaces/SpaceStore";
import { MetaSpace } from "matrix-react-sdk/src/stores/spaces";
import { arrayDiff, arrayHasOrderChange, moveElement } from "matrix-react-sdk/src/utils/arrays";
import { FetchRoomFn } from "matrix-react-sdk/src/stores/notifications/ListNotificationState";
import { RoomNotificationStateStore } from "matrix-react-sdk/src/stores/notifications/RoomNotificationStateStore";
import { EEventType } from '@ctalk/enums/event-type.enum';
import { debounce } from "lodash";


import { ChatFolderNotificationState } from "./ChatFolderNotificationState";
import { getChatFolders, updateChatFolders } from ".";
import { IChatFolder } from "../../../interfaces/chat_folders/IChatFolder";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IState {}

export const UPDATE_SELECTED_FOLDER = Symbol("selected-folder");
export const UPDATE_TOP_LEVEL_FOLDERS = Symbol("top-level-folders");

const ACTIVE_CHAT_FOLDER_LS_KEY = "ck_active_folder";

const getRoomFn: FetchRoomFn = (room: Room) => {
    return RoomNotificationStateStore.instance.getRoomState(room);
};

export class ChatFolderStore extends AsyncStoreWithClient<IState> {
    // The spaces representing the roots of the various tree-like hierarchies
    private chatFolders: IChatFolder[] = [];
    private folderOrderLocal: string[] = [];

    // Map from SpaceKey to SpaceNotificationState instance representing that folder
    private notificationStateMap = new Map<string, ChatFolderNotificationState>();
    // Map from SpaceKey to Set of room IDs that are direct descendants of that folder
    private roomIdsByFolder = new Map<string, Set<string>>(); // won't contain MetaSpace.People
    // Map from folder id to Set of user IDs that are direct descendants of that folder
    private userIdsBFolder = new Map<string, Set<string>>(); // roomId direct
    // cache that stores the aggregated lists of roomIdsByFolder and userIdsBFolder
    // cleared on changes
    private _aggregatedSpaceCache = {
        roomIdsByFolder: new Map<string, Set<string>>(),
        userIdsBFolder: new Map<string, Set<string>>(),
    };
    // The folder currently selected in the Space Panel
    private _activeFolder = ''; // set properly by onReady
    private _msc3946ProcessDynamicPredecessor: boolean = SettingsStore.getValue("feature_dynamic_room_predecessors");

    public constructor() {
        super(defaultDispatcher, {});
    }

    private static readonly internalInstance = ((): ChatFolderStore => {
        const instance = new ChatFolderStore();
        instance.start();
        return instance;
    })();

    public static get instance(): ChatFolderStore {
        return ChatFolderStore.internalInstance;
    }

    public get chatFolderPanel(): IChatFolder[] {
        return this.chatFolders;
    }

    public get activeFolder(): string {
        // FolderId
        return this._activeFolder;
    }

    /**
     * Sets the active folder, updates room list filters,
     * optionally switches the user's room back to where they were when they last viewed that folder.
     * @param folder which folder to switch to.
     * @param contextSwitch whether to switch the user's context,
     * should not be done when the folder switch is done implicitly due to another event like switching room.
     */
    public setActiveFolder(folderId: string): void {
        if (!this.matrixClient || folderId === this.activeFolder) return;

        window.localStorage.setItem(ACTIVE_CHAT_FOLDER_LS_KEY, (this._activeFolder = folderId)); // Update & persist selected folder;

        this.emit(UPDATE_SELECTED_FOLDER, this.activeFolder);
        this.emit(UPDATE_TOP_LEVEL_FOLDERS, this.chatFolderPanel);
    }

    // public addRoomToSpace(folder: Room, roomId: string, via: string[], suggested = false): Promise<ISendEventResponse> {
    //     return this.matrixClient!.sendStateEvent(
    //         folder.roomId,
    //         EventType.SpaceChild,
    //         {
    //             via,
    //             suggested,
    //         },
    //         roomId,
    //     );
    // }

    public isRoomInFolder(roomId: string, folderId?: string): boolean {
        if (!folderId) {
            return false;
        }

        if (this.getFolderFilteredRoomIds(folderId)?.has(roomId)) {
            return true;
        }

        const dmPartner = DMRoomMap.shared().getUserIdForRoomId(roomId);
        if (!dmPartner) {
            return false;
        }

        if (this.getFolderFilteredUserIds(folderId)?.has(dmPartner)) {
            return true;
        }

        return false;
    }

    // Get all rooms in a folder
    public getFolderFilteredRoomIds = (
        folderId: string,
        useCache = true,
    ): Set<string> => {
        return this.roomIdsByFolder.get(folderId) || new Set();
    };

    public getFolderFilteredUserIds = (
        folderId: string,
        useCache = true,
    ): Set<string> | undefined => {
        return this.userIdsBFolder.get(folderId) || new Set();
    };

    private rebuildFolderHierarchy = (initial?: boolean): void => {

        if (!this.matrixClient) return;
        // Fetch data from AccountData
        const fetchFolders = getChatFolders(this.matrixClient);
        const oldFolders = this.chatFolders;

        if (initial) {
            this.chatFolders = fetchFolders;
            this.folderOrderLocal = fetchFolders?.map((f) => f.id);
        } else {
            this.chatFolders = this.sortFoldersByReference(fetchFolders, this.folderOrderLocal);
        }

        this.onRoomsUpdate();
        if (arrayHasOrderChange(oldFolders, this.chatFolders)) {
            const oldFoldersIds = oldFolders.map((e) => e.id);
            const newFoldersIds = this.chatFolders.map((e) => e.id);
            const diffFolders = arrayDiff(oldFoldersIds, newFoldersIds);
            for (const folderId of diffFolders.removed) {
                // Remove storage layout
                localStorage.removeItem(`ck_folder_sublist_layout_${folderId}`);
                // Switch to Home space if actived folder is deleted
                if (this.activeFolder === folderId) {
                    this.switchHomeSpace();
                }
            }
            this.emit(UPDATE_TOP_LEVEL_FOLDERS, this.chatFolderPanel);
        }
    };

    private updateNotificationStates = (folderIds?: string[]): void => {
        if (!this.matrixClient) return;
        const visibleRooms = this.matrixClient.getVisibleRooms(this._msc3946ProcessDynamicPredecessor);

        folderIds!.forEach(f => {
            const roomIdsInFolder = this.getFolderFilteredRoomIds(f);
            // Update NotificationStates
            const listRoomsInFolder = visibleRooms.filter((room) => roomIdsInFolder.has(room.roomId));
            this.getNotificationState(f).setRooms(listRoomsInFolder);
        });
    };

    public getNotificationState(folderId: string): ChatFolderNotificationState {
        if (this.notificationStateMap.has(folderId)) {
            return this.notificationStateMap.get(folderId)!;
        }

        const state = new ChatFolderNotificationState(getRoomFn);
        this.notificationStateMap.set(folderId, state);
        return state;
    }

    private onRoomsUpdate = (): void => {
        if (!this.matrixClient) return;
        // Fetch list room
        const visibleRoomsJoined = this.matrixClient.getVisibleRooms(this._msc3946ProcessDynamicPredecessor).filter((r) => {
            return r.getMyMembership() === "join";
        });

        const prevRoomsByFolder = this.roomIdsByFolder;

        this.roomIdsByFolder = new Map();
        this.userIdsBFolder = new Map();

        this.chatFolders.forEach((f) => {
            const roomsInFolder = visibleRoomsJoined.filter((r) => f.rooms.includes(r.roomId));
            this.roomIdsByFolder.set(f.id, new Set(roomsInFolder.map((r) => r.roomId)));
        });

        const roomDiff = mapDiff(prevRoomsByFolder, this.roomIdsByFolder);
        // filter out keys which changed by reference only by checking whether the sets differ
        const roomsChanged = roomDiff.changed.filter((k) => {
            return setHasDiff(prevRoomsByFolder.get(k)!, this.roomIdsByFolder.get(k)!);
        });

        const changeSet = new Set([
            ...roomDiff.added,
            ...roomDiff.removed,
            ...roomsChanged,
        ]);

        // bust aggregate cache
        this._aggregatedSpaceCache.roomIdsByFolder.clear();

        changeSet.forEach((k) => {
            this.emit(k);
        });

        // if (changeSet.has(this.activeFolder)) {
        //     this.switchFolderIfNeeded();
        // }

        const notificationStatesToUpdate = [...changeSet];
        this.updateNotificationStates(notificationStatesToUpdate);
    };

    private onRoom = (room: Room, newMembership?: string, oldMembership?: string): void => {
        const roomMembership = room.getMyMembership();
        if (!roomMembership || !this.matrixClient) {
            return;
        }
        const membership = newMembership || roomMembership;
        if (membership === "leave" || membership === "ban") {
            this.onRoomsUpdate();
            // Update account data chat folders
            const data = this.chatFolders;
            for (const f of data) {
                if (f.rooms.includes(room.roomId)) {
                    f.rooms = f.rooms.filter((roomId) => roomId !== room.roomId);
                }
            }
            updateChatFolders(this.matrixClient, data);
        }
    };

    private onRoomAccountData = (): void => {
        // TODO handle change if related
    };

    private onRoomState = (ev: MatrixEvent): void => {
        // TODO handle change if related
    };

    private onRoomStateMembers = (ev: MatrixEvent): void => {
        // TODO handle change if related
    };

    private onAccountData = (ev: MatrixEvent, prevEv?: MatrixEvent): void => {
        if (ev.getType() === EEventType.ChatFolder) {
            this.rebuildFolderHierarchy(true);
        }
    };

    private switchHomeSpace = (): void => {
        // Switch to HomeSpace if not valid folderID or RoomId in folder
        SpaceStore.instance.setActiveSpace(MetaSpace.Home, false);
        ChatFolderStore.instance.setActiveFolder("");
    };


    private sortFoldersByReference(folders: IChatFolder[], referenceOrder: string[]): IChatFolder[] {
        return folders?.sort((a, b) => {
            return referenceOrder?.indexOf(a.id) - referenceOrder?.indexOf(b.id);
        });
    }

    private onUpdateOrderFolders = debounce((): void => {
        updateChatFolders(this.matrixClient!, this.chatFolders);
    }, 2000); // 2s

    public moveFolder(fromIndex: number, toIndex: number): void {
        this.folderOrderLocal = moveElement(this.folderOrderLocal, fromIndex, toIndex);
        this.chatFolders = this.sortFoldersByReference(this.chatFolders, this.folderOrderLocal);
        this.onUpdateOrderFolders();
    }

    protected async reset(): Promise<void> {
        this.chatFolders = [];
        this.notificationStateMap = new Map();
        this.roomIdsByFolder = new Map();
        this.userIdsBFolder = new Map();
        this._aggregatedSpaceCache.roomIdsByFolder.clear();
        this._aggregatedSpaceCache.userIdsBFolder.clear();
        this._activeFolder = ""; // set properly by onReady
    }

    protected async onNotReady(): Promise<void> {
        if (this.matrixClient) {
            this.matrixClient.removeListener(ClientEvent.Room, this.onRoom);
            this.matrixClient.removeListener(RoomEvent.MyMembership, this.onRoom);
            this.matrixClient.removeListener(RoomEvent.AccountData, this.onRoomAccountData);
            this.matrixClient.removeListener(RoomStateEvent.Events, this.onRoomState);
            this.matrixClient.removeListener(RoomStateEvent.Members, this.onRoomStateMembers);
            this.matrixClient.removeListener(ClientEvent.AccountData, this.onAccountData);
        }
        await this.reset();
    }

    protected async onReady(): Promise<void> {
        if (!this.matrixClient) return;
        this.matrixClient.on(ClientEvent.Room, this.onRoom);
        this.matrixClient.on(RoomEvent.MyMembership, this.onRoom);
        this.matrixClient.on(RoomEvent.AccountData, this.onRoomAccountData);
        this.matrixClient.on(RoomStateEvent.Events, this.onRoomState);
        this.matrixClient.on(RoomStateEvent.Members, this.onRoomStateMembers);
        this.matrixClient.on(ClientEvent.AccountData, this.onAccountData);
        this.rebuildFolderHierarchy(true); // trigger an initial update

        // restore selected state from last session if any and still valid
        const lastSpaceId = window.localStorage.getItem(ACTIVE_CHAT_FOLDER_LS_KEY);
        const valid = lastSpaceId && getChatFolders(this.matrixClient)?.some((f) => f.id === lastSpaceId);
        if (valid) {
            // don't context switch here as it may break permalinks
            this.setActiveFolder(lastSpaceId);
        } else {
            this.switchHomeSpace();
        }
    }

    protected async onAction(payload: any): Promise<void> {
        // TODO: Action delete folder => if active => switch to HomeSpace
    }
}
