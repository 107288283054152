import { RefObject } from "react";
import { NotificationCount, Room } from "matrix-js-sdk/src/models/room";
import { RoomMember } from "matrix-js-sdk/src/models/room-member";
import { EventTimeline, EventType, MatrixClient, MatrixEvent, RoomEvent } from "matrix-js-sdk/src/matrix";
import { MatrixClientPeg } from "matrix-react-sdk/src/MatrixClientPeg";
import DMRoomMap from "matrix-react-sdk/src/utils/DMRoomMap";
import { DefaultTagID } from "matrix-react-sdk/src/stores/room-list/models";
import LegacyCallHandler from "matrix-react-sdk/src/LegacyCallHandler";
import { CallState } from "matrix-js-sdk/src/webrtc/call";
import * as RoomNotifs from "matrix-react-sdk/src/RoomNotifs";
import { NotificationColor } from "matrix-react-sdk/src/stores/notifications/NotificationColor";
import { RoomNotificationStateStore } from "matrix-react-sdk/src/stores/notifications/RoomNotificationStateStore";
import { SortAlgorithm } from "matrix-react-sdk/src/stores/room-list/algorithms/models";
import { sortRooms as sortRoomsRecent } from "matrix-react-sdk/src/stores/room-list/algorithms/tag-sorting/RecentAlgorithm";
import { Layout } from "matrix-react-sdk/src/settings/enums/Layout";
import { TimelineRenderingType } from "matrix-react-sdk/src/contexts/RoomContext";
import { IRoomState } from "matrix-react-sdk/src/components/structures/RoomView";
import {
    aboveLeftOf,
    aboveRightOf,
    ChevronFace,
    MenuProps,
} from "matrix-react-sdk/src/components/structures/ContextMenu";
import UIStore from "matrix-react-sdk/src/stores/UIStore";

import { isBotUser } from "../utils/helper";
import { ERoomRole } from "../enums/room-role.enum";

const SORT_REGEX = /[\x21-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E]+/g;
const sortNames = new Map<RoomMember, string>(); // RoomMember -> sortName

export function isDirectMessage(roomId: string): boolean {
    return !!DMRoomMap.shared().getUserIdForRoomId(roomId);
}

export function getMemberLeaveRoomInDirectMessage(room: Room): RoomMember | null {
    const isDm = isDirectMessage(room.roomId);
    if (!room || !isDm) return null;
    const cli = MatrixClientPeg.safeGet();
    const allMembers = Object.values(room.currentState.members);

    allMembers.forEach((member) => {
        // work around a race where you might have a room member object
        // before the user object exists. This may or may not cause
        // https://github.com/vector-im/vector-web/issues/186
        if (!member.user) {
            member.user = cli.getUser(member.userId)!;
        }

        sortNames.set(
            member,
            (member.name[0] === '@' ? member.name.slice(1) : member.name).replace(SORT_REGEX, ""),
        );

        // XXX: this user may have no lastPresenceTs value!
        // the right solution here is to fix the race rather than leave it as 0
    });

    const membersLeave = allMembers.filter(member => member.membership === 'leave');
    return membersLeave[0];
}

export function getBotInDirectMessage(room: Room): RoomMember | null | undefined {
    const isDm = isDirectMessage(room.roomId);
    if (!room || !isDm) return;
    const allMembers = Object.values(room.currentState.members);
    return allMembers.find(member => isBotUser(member.userId));
}

export function setLatestPinnedStateEvents(cli: MatrixClient, room: Room): void {
    if (!room) {
        return;
    }
    cli.getStateEvent(room.roomId, EventType.RoomPinnedEvents, "").then((res) => {
        room.getLiveTimeline()?.getState(EventTimeline.FORWARDS)?.setStateEvents([new MatrixEvent({
            type: EventType.RoomPinnedEvents,
            state_key: "",
            content: { pinned: res.pinned },
            event_id: "$fake" + Date.now(),
            room_id: room.roomId,
            user_id: cli.getSafeUserId(),
        })]);
    });
}

export function isMemberInRoom(cli: MatrixClient, roomId: string, userId: string): boolean {
    const room = cli.getRoom(roomId);
    if (!room) {
        return false;
    }
    const roomMembers = room.getJoinedMembers()?.map((member) => {
        return member.userId;
    });
    return roomMembers?.includes(userId);
}

export async function forceLeaveRoomDeleted(cli: MatrixClient, roomId: string): Promise<void> {
    const room = cli?.getRoom(roomId);
    // Handle leave room on local with deleted room
    room?.updateMyMembership("leave");
    // Remove room on local store
    await cli?.store.removeRoom(roomId);
    await cli?.store.save(true);
    // Handle clear data sync in indexDB (clear data room stuck)
    const db = await new Promise((resolve, reject) => {
        const dbReq = indexedDB.open('matrix-js-sdk:riot-web-sync');
        dbReq.onerror = reject;
        dbReq.onsuccess = (): void => resolve(dbReq.result);
    }) as any;
    const txn = db!.transaction(['sync'], 'readwrite') as IDBTransaction;
    txn!.objectStore('sync').clear();
}

export function forceHangUpOrRejectLegacyCall(roomId: string): void {
    const activeCall = LegacyCallHandler.instance.getCallForRoom(roomId);
    if (activeCall?.state === CallState.Ringing) {
        LegacyCallHandler.instance.hangupOrReject(roomId, true);
    }
    if (activeCall?.state === CallState.Connected) {
        LegacyCallHandler.instance.hangupOrReject(roomId, false);
    }
}

export function isActiveLegacyCall(roomId: string): boolean {
    const activeCall = LegacyCallHandler.instance.getCallForRoom(roomId);
    return activeCall?.state === CallState.Ringing || activeCall?.state === CallState.Connected;
}

// noinspection JSUnusedGlobalSymbols
export function isRoomAdmin(cli: MatrixClient, room: Room): boolean {
    const me = room?.getMember(cli.getSafeUserId());
    if (!me) return false;
    return me.powerLevel === ERoomRole.ADMIN;
}

export function isUserRoleInRoom(role: ERoomRole, member?: RoomMember | null): boolean {
    if (!member) return false;
    return member.powerLevel === role;
}

export function isServerNoticeRoom(roomId: string, cli?: MatrixClient): boolean {
    if (!cli) {
        cli = MatrixClientPeg.safeGet();
    }
    return !!cli.getRoom(roomId)?.tags[DefaultTagID.ServerNotice];
}

export function isMemberJoinedAndInvited(cli: MatrixClient, roomId: string): boolean {
    const room = cli.getRoom(roomId);
    if (!room) {
        return false;
    }
    const selfMembership = room.getMyMembership();
    const statuses = ['invite', 'join'];
    return statuses.includes(selfMembership);
}

export function sortRoomsAlphabetic(rooms: Room[]): Room[] {
    const collator = new Intl.Collator();
    return rooms.sort((a, b) => {
        return collator.compare(a.name, b.name);
    });
}

export function sortingRooms(rooms: Room[], sort: SortAlgorithm): Room[] {
    if (sort === SortAlgorithm.Alphabetic) {
        return sortRoomsAlphabetic(rooms);
    } else {
        return sortRoomsRecent(rooms);
    }
}

export function orderRoomUnreadFirst(rooms: Room[], sort: SortAlgorithm): Room[] {
    type CategorizedRoomMap = {
        [category in NotificationColor]: Room[]; // TODO update NotificationLevel
    };

    // type CategoryIndex = Partial<{
    //     [category in NotificationColor]: number; // integer
    // }>;

    const CATEGORY_ORDER = [
        NotificationColor.Unsent,
        NotificationColor.Red,
        NotificationColor.Grey,
        NotificationColor.Bold,
        NotificationColor.None, // idle
        NotificationColor.Muted,
    ];

    const getRoomCategory = (room: Room): NotificationColor => {
        const state = RoomNotificationStateStore.instance.getRoomState(room);
        return state.muted ? NotificationColor.Muted : state.color;
    }

    const categorizeRooms = (rooms: Room[]): CategorizedRoomMap =>  {
        const map: CategorizedRoomMap = {
            [NotificationColor.Unsent]: [],
            [NotificationColor.Red]: [],
            [NotificationColor.Grey]: [],
            [NotificationColor.Bold]: [],
            [NotificationColor.None]: [],
            [NotificationColor.Muted]: [],
        };
        for (const room of rooms) {
            const category = getRoomCategory(room);
            map[category]?.push(room);
        }
        return map;
    };

    // Every other sorting type affects the categories, not the whole tag.
    const categorized = categorizeRooms(rooms);

    for (const category of Object.keys(categorized)) {
        const notificationColor = category as unknown as NotificationColor;
        const roomsToOrder = categorized[notificationColor];
        categorized[notificationColor] = sortingRooms(roomsToOrder, sort);
    }

    const newlyOrganized: Room[] = [];
    // const newIndices: CategoryIndex = {};

    for (const category of CATEGORY_ORDER) {
        // newIndices[category] = newlyOrganized.length;
        newlyOrganized.push(...categorized[category]);
    }

    // this.indices = newIndices;
    return newlyOrganized;
}

export function onEmitResetCount(room: Room): void {
    const { count } = RoomNotifs.determineUnreadState(room);
    if (!count) {
        return;
    }
    const notification: NotificationCount = {
        highlight: -1,
        total: -1,
    };
    room.emit(RoomEvent.UnreadNotifications, notification);
}

export function roomContextValue(room: Room): IRoomState & {
    threadId?: string;
} {
    return {
        room: room, // This should be of type Room or undefined
        threadId: undefined, // Optional property
        roomLoading: false,
        peekLoading: false,
        shouldPeek: true,
        membersLoaded: false,
        numUnreadMessages: 0,
        canPeek: false,
        showApps: false,
        isPeeking: false,
        showRightPanel: true,
        threadRightPanel: false,
        joining: false,
        showTopUnreadMessagesBar: false,
        statusBarVisible: false,
        canReact: false,
        canSelfRedact: false,
        canSendMessages: false,
        resizing: false,
        layout: Layout.Group,
        lowBandwidth: false,
        alwaysShowTimestamps: false,
        showTwelveHourTimestamps: false,
        readMarkerInViewThresholdMs: 3000,
        readMarkerOutOfViewThresholdMs: 30000,
        showHiddenEvents: false,
        showReadReceipts: true,
        showRedactions: true,
        showJoinLeaves: true,
        showAvatarChanges: true,
        showDisplaynameChanges: true,
        matrixClientIsReady: false,
        showUrlPreview: false,
        timelineRenderingType: TimelineRenderingType.Room,
        liveTimeline: undefined,
        narrow: false,
        activeCall: null,
        msc3946ProcessDynamicPredecessor: false,
        canAskToJoin: false,
        promptAskToJoin: false,
        viewRoomOpts: { buttons: [] },
    };
}

export function getMenuPosition(ref: RefObject<HTMLElement>, isAboveRightOf = false): MenuProps | undefined {
    if (ref.current) {
        // const hasFormattingButtons = this.state.isWysiwygLabEnabled && this.state.isRichTextEnabled;
        const hasFormattingButtons = false;
        const contentRect = ref.current.getBoundingClientRect();
        // Here we need to remove the all the extra space above the editor
        // Instead of doing a querySelector or pass a ref to find the compute the height formatting buttons
        // We are using an arbitrary value, the formatting buttons height doesn't change during the lifecycle of the component
        // It's easier to just use a constant here instead of an over-engineering way to find the height
        const heightToRemove = hasFormattingButtons ? 36 : 0;
        const fixedRect = new DOMRect(
            contentRect.x,
            contentRect.y + heightToRemove,
            contentRect.width,
            contentRect.height - heightToRemove,
        );

        if (isAboveRightOf) {
            const wysiwygComposerLeft = document.querySelector('.ck_SendWysiwygComposer_Left');
            if (wysiwygComposerLeft) {
                const wysiwygRect = wysiwygComposerLeft.getBoundingClientRect();

                const leftValue = wysiwygRect.left + window.scrollX;
                const topValue = wysiwygRect.top + window.scrollY;
                const bottomValue = UIStore.instance.windowHeight - topValue;

                return {
                    left: leftValue,
                    bottom: bottomValue,
                    chevronFace: ChevronFace.None,
                };
            }

            return aboveRightOf(fixedRect);
        }
        return aboveLeftOf(fixedRect);
    }
}
